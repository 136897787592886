import React from 'react';
import DashNavigation from './DashNavigation/DashNavigation';
import './Display.scss';

function Display() {
  return (
    <div className="dashboard-display">
      <h1 className="dashboard-display-h1">Display Area</h1>
      <DashNavigation />
    </div>
  );
};

export default Display;