import React from 'react';
import './LoginForm.scss';

function LoginForm() {
  return (
    <div className="login-form">
      <div className="login-form-container">
        <h1 className="login-form-title">Login</h1>
        <form>
          <label>
            Username:
            <input type="text" name="username" placeholder="Username" className="login-form-input" />
          </label>
          <br />
          <label>
            Password:
            <input type="password" name="password" placeholder="Password" className="login-form-input" />
          </label>
          <br />
          <div className="login-form-buttons">
            <button type="submit" className="login-form-button">Login</button>
          </div>
        </form>

        {/* <div className="login-form-error">Error message here</div> */}

        {/* <a href="/forgot-password" className="login-form-forgot-password">Forgot Password?</a> */}
        <a href="/login" className="login-form-forgot-password">Forgot Password?</a> {/* Change this to the correct path once component is completed...*/}
        <a href="/signup" className="login-form-signup">Sign Up</a>
        <div className="login-form-social-media">
        <a href="https://www.facebook.com/profile.php?id=61563184355366">
        <img className="login-form-social-media-icon-image" src="/images/social-media-icons/facebook-logo.png" alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/berzen.digital.assets">
                <img src="/images/social-media-icons/instagram-logo.png" alt="Instagram" />
            </a>        {/* Add other social media icons as needed */}
        </div>
      </div>
    </div>
  );
};

export default LoginForm;