import React from 'react';
import './SignupForm.scss';

function SignupForm() {
  return (
    <div className="signup-form">
      <div className="signup-form-container">
        <h1 className="signup-form-title">Sign Up</h1>
        <form>
          <label>
            Username:
            <input type="text" name="username" placeholder="Username" className="signup-form-input" />
          </label>
          <br />
          <label>
            Email:
            <input type="email" name="email" placeholder="Email" className="signup-form-input" />
          </label>
          <br />
          <label>
            Password:
            <input type="password" name="password" placeholder="Password" className="signup-form-input" />
          </label>
          <br />
          <label>
            Confirm Password:
            <input type="password" name="confirmPassword" placeholder="Confirm Password" className="signup-form-input" />
          </label>
          <br />
          <button type="submit" className="signup-form-button">Sign Up</button>
        </form>
        {/* <div className="signup-form-error">Error message here</div> */}
        <a href="/login" className="signup-form-login">Already have an account? Login</a>
        <div className="signup-form-social-media">
          <a href="https://www.facebook.com/profile.php?id=61563184355366">
            <img className="signup-form-social-media-icon-image" src="/images/social-media-icons/facebook-logo.png" alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/berzen.digital.assets">
            <img src="/images/social-media-icons/instagram-logo.png" alt="Instagram" />
          </a>
          {/* Add other social media icons as needed */}
        </div>
      </div>
    </div>
  );
}

export default SignupForm;
