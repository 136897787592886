import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.scss';

function HomePage() {
  return (
    <div className="home-page">
      <h1>Berzen Digital Assets</h1>
        <Link to="/login">
          <button className="home-page-button">Login</button>
        </Link>
        <Link to="/signup">
          <button className="home-page-button">Sign Up</button>
        </Link>
      {/* Additional content or sections can be added here */}
    </div>
  );
};

export default HomePage;