import React from 'react';
import './DashNavigation.scss';

function DashNavigation() {
  return (
    <div className="dash-navigation">
        <h1 className="dash-navigation-h1">Menu options</h1>
    </div>
  );
};

export default DashNavigation;