import React from 'react';
import Display from '../../Components/Dashboard/Display';
import './DashboardPage.scss';

function DashboardPage() {
  return (
    <div className="dashboard-page">
        <h1 className="dashboard-page-h1">Dashboard Page</h1>
        <Display />
    </div>
  );
};

export default DashboardPage;