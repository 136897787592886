// Node package dependencies
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Pages
import HomePage from './Pages/HomePage/HomePage';
import DashboardPage from './Pages/DashboardPage/DashboardPage';

// Components
import LoginForm from './Components/LoginForm/LoginForm';
import SignupForm from './Components/SignupForm/SignupForm';

function BerzenRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/signup" element={<SignupForm />} />
          <Route path="/dashboard" element={<DashboardPage />} />
        </Routes>
    </BrowserRouter>
  );
};

export default BerzenRoutes;